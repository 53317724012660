<!--
    Props:
    - data: Array
    - columns: Array
    - options: Object
    - tableId: String

    Events:
    - rowClick
    - sortColumn
    - limit

    Options:
    - striped: Boolean
    - hover: Boolean
    - selectable: Object
        - enabled: Boolean
        - key: String
        - buttonLabel: String
        - buttonAction: Function
    - sort: Boolean
    - limit: Boolean

    Columns:
    - name: String
    - label: String
    - formatter: Function
    - content: Function
    - condition: Function
    - component: String
    - action: Function
    - options: Object
        - backgroundColor: String
        - textColor: String
        - icon: String
        - text: String
        - sortable: Boolean

    Usage:
    <datatable
        :data="data"
        :columns="columns"
        :options="options"
        tableId="tableId"
        @rowClick="rowClick"
        @sortColumn="sortColumn"
    />
 -->
<script setup>
import { useDatatableStore } from '@/js/stores/DatatableStore';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
        required: true,
    },
    filters: {
        type: Object,
        required: false,
    },
    columns: {
        type: Array,
        required: true,
    },
    options: {
        type: Object,
        required: true,
    },

    tableId: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['rowClick', 'sortColumn', 'limit', 'filters']);

const datatableStore = useDatatableStore();
const tableRef = ref(null);
const limit = ref(15);
const stripedClass = computed(() => (props.options.striped ? 'striped' : ''));
const hoverClass = computed(() => (props.options.hover ? 'hover' : ''));
const shadowClass = computed(() =>
    props.options.shadow ? 'shadow' : 'border border-gray-200',
);
const filters = computed(() => datatableStore.getFilters(props.tableId));

const select = (event, row) => {
    if (event.target.checked) {
        datatableStore.addSelected({
            tableId: props.tableId,
            value: row[props.options.selectable.key],
        });
    } else {
        datatableStore.removeSelected({
            tableId: props.tableId,
            value: row[props.options.selectable.key],
        });
    }
};

const updateLimit = event => {
    emit('limit', event.value);
};

watch(
    () => props.filters,
    () => {
        if (props.filters) {
            datatableStore.setFilters({
                tableId: props.tableId,
                filters: props.filters,
            });
        }
    },
    { deep: true },
);

onMounted(() => {
    if (Object.keys(filters.value).length !== 0) {
        filters.value.limit ? (limit.value = filters.value.limit) : null;
        emit('filters', filters.value);
    }
});

onUnmounted(() => {
    datatableStore.setLastTableId(props.tableId);
});

const selectAll = event => {
    if (event.target.checked) {
        datatableStore.selectAll({
            tableId: props.tableId,
            data: props.data.map(row => row[props.options.selectable.key]),
        });
    } else {
        datatableStore.deselectAll({
            tableId: props.tableId,
            data: props.data.map(row => row[props.options.selectable.key]),
        });
    }
};

const sortColumn = column => {
    datatableStore.toggleSortColumn({
        tableId: props.tableId,
        column: column.name,
    });
    emit('sortColumn', {
        column: datatableStore.getSortColumn(props.tableId),
        direction: datatableStore.getSortDirection(props.tableId),
    });
};

const handleClick = (row, event) => {
    if (event.ctrlKey || event.metaKey) {
        emit('rowClick', { row, ctrlKey: true });
    } else {
        emit('rowClick', { row, ctrlKey: false });
    }
};
</script>

<template>
    <div class="overflow-x-auto sm:rounded-lg" :class="[shadowClass]">
        <div class="options" v-if="options.selectable?.enabled">
            <button
                @click="
                    datatableStore.toggleSelectedIsActive({
                        tableId: props.tableId,
                    })
                "
                class="px-2 py-1 rounded text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
            >
                {{
                    datatableStore.getSelectedIsActive(props.tableId)
                        ? 'Annuler'
                        : 'Sélectionner'
                }}
            </button>
            <button
                v-if="datatableStore.getSelectedIsActive(props.tableId)"
                @click="
                    options.selectable.buttonAction(
                        datatableStore.getSelected(props.tableId),
                    )
                "
                class="px-2 py-1 ml-2 rounded text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
                :disabled="datatableStore.getSelectedCount(props.tableId) === 0"
            >
                {{ options.selectable.buttonLabel }}
            </button>
        </div>
        <table
            ref="tableRef"
            :id="props.tableId"
            :class="['min-w-full', 'bg-white', stripedClass, hoverClass]"
        >
            <thead>
                <tr
                    class="border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                    <th
                        v-if="
                            datatableStore.getSelectedIsActive(props.tableId) &&
                            options.selectable?.enabled
                        "
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        style="width: 1%"
                    >
                        <input
                            type="checkbox"
                            :checked="
                                datatableStore.allIsSelected(
                                    props.tableId,
                                    props.data.map(
                                        row => row[options.selectable.key],
                                    ),
                                )
                            "
                            @click.stop="selectAll($event)"
                        />
                    </th>
                    <template v-for="column in columns" :key="column.name">
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            @click.stop="
                                column.options?.sort == null ||
                                column.options?.sort == true
                                    ? options.sort && !column.component
                                        ? sortColumn(column)
                                        : null
                                    : null
                            "
                        >
                            {{ column.label }}
                            <div
                                v-show="
                                    column.options?.sort == null ||
                                    column.options?.sort == true
                                "
                                v-if="options.sort && !column.component"
                                class="inline-block ml-1 cursor-pointer"
                            >
                                <i
                                    v-if="
                                        datatableStore.getSortColumn(
                                            props.tableId,
                                        ) === column.name &&
                                        datatableStore.getSortDirection(
                                            props.tableId,
                                        ) === 'asc'
                                    "
                                    class="fas fa-sort-up"
                                ></i>
                                <i
                                    v-else-if="
                                        datatableStore.getSortColumn(
                                            props.tableId,
                                        ) === column.name &&
                                        datatableStore.getSortDirection(
                                            props.tableId,
                                        ) === 'desc'
                                    "
                                    class="fas fa-sort-down"
                                ></i>
                                <i v-else class="fas fa-sort"></i>
                            </div>
                        </th>
                    </template>
                    <th
                        v-if="options.limit"
                        class="flex justify-end px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                        <drop-down
                            :options="[10, 15, 20, 50, 100, 250, 500, 1000]"
                            @change="updateLimit($event)"
                            v-model="limit"
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="row in data" :key="row.id">
                    <tr>
                        <td
                            v-if="
                                datatableStore.getSelectedIsActive(
                                    props.tableId,
                                ) && options.selectable?.enabled
                            "
                        >
                            <input
                                type="checkbox"
                                :value="row[options.selectable.key]"
                                :checked="
                                    datatableStore
                                        .getSelected(props.tableId)
                                        .includes(row[options.selectable.key])
                                "
                                :disabled="row.danger"
                                @click.stop="select($event, row)"
                            />
                        </td>
                        <template
                            v-for="(column, index) in columns"
                            :key="column.name"
                        >
                            <td
                                class="px-6 py-2 whitespace-no-wrap border-b border-gray-200 text-wrap"
                                :class="{
                                    'bg-danger': row.danger,
                                }"
                                :colspan="index == columns.length - 1 ? 2 : 1"
                                :style="column.style"
                                @click.stop="handleClick(row, $event)"
                            >
                                <span
                                    v-if="
                                        column.condition
                                            ? column.condition(row)
                                            : true
                                    "
                                >
                                    <span v-if="column.component === 'Button'">
                                        <primary-button
                                            small
                                            @click.stop="column.action(row)"
                                            :style="`background-color: ${column.options.backgroundColor}; color: ${column.options.textColor}`"
                                        >
                                            <i
                                                v-if="column.options.icon"
                                                :class="column.options.icon"
                                            ></i>
                                            {{ column.options.text ?? '' }}
                                        </primary-button>
                                    </span>
                                    <span
                                        v-else-if="column.image === true"
                                        style="width: 100%"
                                    >
                                        <img
                                            :src="column.src(row)"
                                            class="w-auto"
                                            style="max-width: none"
                                            :style="
                                                column.style ? column.style : ''
                                            "
                                            @error="
                                                e =>
                                                    (e.target.src =
                                                        $apiUrl() +
                                                        '/storage/assets/images/default.png')
                                            "
                                        />
                                    </span>
                                    <span v-else>
                                        <span
                                            v-if="column.html"
                                            v-html="
                                                column.formatter
                                                    ? column.formatter(row)
                                                    : column.content
                                                    ? column.content(row)
                                                    : row[column.name]
                                            "
                                        >
                                        </span>
                                        <span v-else>
                                            {{
                                                column.formatter
                                                    ? column.formatter(row)
                                                    : column.content
                                                    ? column.content(row)
                                                    : row[column.name]
                                            }}
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-if="data?.length === 0">
                    <tr>
                        <td
                            :colspan="columns.length + 1"
                            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center pointer-events-none"
                        >
                            Aucune donnée disponible
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

th,
td {
    text-align: left;
    padding: 0.75rem;
    white-space: nowrap;
}

tbody tr {
    padding: 0;
    transition: background-color 0.3s;
}

thead th {
    user-select: none;
}

input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    margin-bottom: 0;
    cursor: pointer;
    &:checked {
        background-color: #ccc;
        border-color: #ccc;
        + label {
            font-weight: bold;
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: #ccc;
    }
}

.striped tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.hover tbody tr:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.options {
    display: flex;
    justify-content: flex-start;
    padding: 0.75rem;
}

td:not(:has(img)) {
    max-width: 200px;
}

td:has(img) {
    width: 0%;
    white-space: nowrap; /* Prevents text wrapping if there's other content */
}
</style>
