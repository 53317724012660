export default {
    root: ({ props, state, parent }) => ({
        class: [
            'inline-flex',
            'relative',
            { 'rounded-md': parent.instance.$name !== 'InputGroup' },
            {
                'first:rounded-l-md rounded-none last:rounded-r-md':
                    parent.instance.$name == 'InputGroup',
            },
            {
                'border-0 border-y border-l last:border-r':
                    parent.instance.$name == 'InputGroup',
            },
            {
                'first:ml-0 ml-[-1px]':
                    parent.instance.$name == 'InputGroup' && !props.showButtons,
            },
            { 'bg-surface-0': !props.disabled },
            'border',
            { 'border-surface-300': !props.invalid },
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            { 'border-red-500': props.invalid },
            'transition-all',
            'duration-200',
            { 'hover:border-surface-400': !props.invalid },
            {
                'outline-none outline-offset-0 ring-1 ring-primary-500 z-10':
                    state.focused,
            },
            'cursor-pointer',
            'select-none',
            {
                'bg-surface-200 select-none pointer-events-none cursor-default':
                    props.disabled,
            },
        ],
    }),
    input: ({ props, parent }) => ({
        class: [
            'leading-[normal]',
            'block',
            'flex-auto',
            'bg-transparent',
            'border-0',
            {
                'text-surface-800': props.modelValue != undefined,
                'text-surface-400': props.modelValue == undefined,
            },
            'placeholder:text-surface-400',
            'w-[1%]',
            'py-2 px-3',
            { 'pr-7': props.showClear },
            'rounded-none',
            'transition',
            'duration-200',
            'focus:outline-none focus:shadow-none',
            {
                filled:
                    parent.instance?.$name == 'FloatLabel' &&
                    props.modelValue !== null,
            },
            'relative',
            'cursor-pointer',
            'overflow-hidden overflow-ellipsis',
            'whitespace-nowrap',
            'appearance-none',
        ],
    }),
    trigger: {
        class: [
            'flex items-center justify-center',
            'shrink-0',
            'bg-transparent',
            'text-surface-500',
            'w-12',
            'rounded-r-md',
        ],
    },
    panel: {
        class: [
            'bg-surface-0',
            'text-surface-700',
            'border border-surface-300',
            'rounded-md',
            'shadow-md',
        ],
    },
    wrapper: {
        class: ['max-h-[200px]', 'overflow-auto'],
    },
    list: {
        class: 'p-1 list-none m-0',
    },
    item: ({ context }) => ({
        class: [
            'relative',
            'leading-none',
            'm-0 px-3 py-2',
            'first:mt-0 mt-[2px]',
            'border-0 rounded',
            {
                'text-surface-700': !context.focused && !context.selected,
                'bg-surface-200': context.focused && !context.selected,
                'text-primary-highlight-inverse': context.selected,
                'bg-primary-highlight': context.selected,
            },
            { 'hover:bg-surface-100': !context.focused && !context.selected },
            { 'hover:bg-primary-highlight-hover': context.selected },
            {
                'hover:text-surface-700 hover:bg-surface-100':
                    context.focused && !context.selected,
            },
            'transition-shadow duration-200',
            'cursor-pointer overflow-hidden whitespace-nowrap',
        ],
    }),
    itemgroup: {
        class: [
            'font-semibold',
            'm-0 py-2 px-3',
            'text-surface-400',
            'cursor-auto',
        ],
    },
    emptymessage: {
        class: [
            'leading-none',
            'py-2 px-3',
            'text-surface-800',
            'bg-transparent',
        ],
    },
    header: {
        class: [
            'pt-2 px-2 pb-0',
            'm-0',
            'border-b-0',
            'rounded-tl-md',
            'rounded-tr-md',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-300',
        ],
    },
    filtercontainer: {
        class: 'relative',
    },
    filterinput: {
        class: [
            'leading-[normal]',
            'py-2 pl-3 pr-7 mb-0',
            '-mr-7',
            'w-full',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-200',
            'border',
            'rounded-lg',
            'appearance-none',
            'transition',
            'duration-200',
            'hover:border-surface-400',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 focus:z-10',
            'appearance-none',
        ],
    },
    filtericon: {
        class: ['absolute', 'top-1/2 right-3', '-mt-2'],
    },
    clearicon: {
        class: ['text-surface-400', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
    },
    loadingicon: {
        class: 'text-surface-400 animate-spin',
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
    },
};
