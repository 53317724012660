<template>
    <div class="search-container" @focusout="closeOptions">
        <input
            class="search-input"
            type="text"
            :disabled="loadingOptions"
            :class="{
                loading: loadingOptions,
                inputIsInvalid: error && error.inputIsInvalid?.length > 0,
            }"
            v-model="searchQuery"
            :placeholder="dynamicPlaceholder"
            @input="handleInput"
            @focus="openOptions"
        />
        <i
            class="fa fa-close"
            @click="handleReset()"
            style="position: absolute; right: 10px; top: 10px; cursor: pointer"
            v-if="searchQuery !== '' && reset"
        ></i>
        <ul v-show="showOptions" class="search-options">
            <li
                v-for="(option, index) in filteredOptions"
                :key="getOptionKey(option)"
                @click="selectOption(option)"
                :class="{ active: index === activeIndex }"
            >
                {{ getOptionText(option) }}
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

const searchQuery = ref('');
let debounceTimeout;
const activeIndex = ref(-1);
const showOptions = ref(false);
const loadingOptions = ref(false);
const loadingText = ref('Loading');
const dots = ref('');

// Props
const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    reset: {
        type: Boolean,
        default: false,
    },
    valueKey: {
        type: [String, Function],
        default: null,
    },
    textKeys: {
        type: Array,
        default: () => [],
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    placeholder: {
        type: String,
        default: 'Rechercher...',
    },
    errorClass: String,
    error: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue', 'reset']);

const dynamicPlaceholder = computed(() => {
    if (loadingOptions.value) {
        return loadingText.value + dots.value;
    } else {
        return props.placeholder;
    }
});

// Computed properties
const filteredOptions = computed(() => {
    const query = searchQuery.value.toLowerCase(); // Stocker la valeur de la requête en minuscules
    return props.options.filter(option => {
        const text = getOptionText(option).toLowerCase();
        return text.includes(query);
    });
});

// Methods

const handleReset = () => {
    emit('reset');
};
function getOptionText(option) {
    if (typeof option === 'object') {
        if (props.textKeys.length === 0) {
            return '';
        }
        return props.textKeys
            .map(key => {
                if (typeof key === 'function') {
                    return key(option);
                }
                if (key.includes('.')) {
                    const keys = key.split('.');
                    let value = option;
                    for (const nestedKey of keys) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (value && value.hasOwnProperty(nestedKey)) {
                            value = value[nestedKey];
                        } else {
                            value = '';
                            break;
                        }
                    }
                    return value;
                } else {
                    return option[key];
                }
            })
            .join(' ');
    } else {
        return option.toString();
    }
}

function getOptionKey(option) {
    if (props.valueKey) {
        if (typeof props.valueKey === 'function') {
            return props.valueKey(option);
        }
        return option[props.valueKey];
    } else {
        return option.toString();
    }
}

function selectOption(option) {
    const value = getOptionKey(option);
    emit('update:modelValue', value);
    searchQuery.value = '';
    activeIndex.value = -1;
    showOptions.value = false;
}

function handleInput(event) {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
        search(event.target.value);
    }, 500);
}

const search = query => {
    searchQuery.value = query;
    activeIndex.value = -1;
    showOptions.value = true;
};

function setInitialValue() {
    if (!!props.valueKey && !!props.modelValue) {
        const option = props.options.find(option => {
            const value = getOptionKey(option);
            return value === props.modelValue;
        });
        if (option) {
            searchQuery.value = getOptionText(option);
        }
    } else if (props.modelValue == null) {
        searchQuery.value = '';
    } else {
        searchQuery.value = props.modelValue.toString();
    }
    if (props.options) {
        loadingOptions.value = false;
    }
}

function openOptions() {
    setInitialValue();
    showOptions.value = true;
}

function closeOptions() {
    setTimeout(() => {
        showOptions.value = false;
        if (searchQuery.value === '') {
            setInitialValue();
        }
    }, 200);
}

watch(
    () => searchQuery.value,
    () => {
        clearTimeout(debounceTimeout);
        activeIndex.value = -1;
    },
);

watch(props, () => {
    setInitialValue();
});

watch(
    () => props.options,
    () => {
        if (!props.options) {
            loadingOptions.value = true;
        }
        loadingOptions.value = false;
    },
);

function animateDots() {
    setInterval(() => {
        dots.value = dots.value.length === 3 ? '' : dots.value + '.';
    }, 500);
}

onMounted(() => {
    loadingOptions.value = true;
    animateDots();
    setTimeout(() => {
        setInitialValue();
    }, 500);
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.search-container {
    position: relative;
    width: 100%;
    .search-input {
        border: 1px solid #dee2e6;
        margin-bottom: 0 !important;
        &::placeholder {
            color: #bbbbbb;
        }
        &.loading {
            cursor: not-allowed;
        }
    }
    .search-options {
        position: absolute;
        top: calc(100% + 20px);
        left: 0;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: -21px;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        border: 1px solid $greyColor;
        box-shadow: $defaultShadow;
        border-top: none;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        background: $whiteColor;
        z-index: 9997;

        li {
            cursor: pointer;
            padding: 0.5rem;
            &.active {
                background-color: $whiteColor;
                color: $whiteColor;
            }
            &:hover {
                background-color: $greenColor;
                color: $whiteColor;
            }
        }
    }
}
</style>
