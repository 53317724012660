<template>
    <div
        v-for="(notification, index) in popupStore.$state.notifications"
        :key="index"
        class="popup"
        :class="{
            success: notification.type == 'success',
            warning: notification.type == 'warning',
            info: notification.type == 'info',
        }"
        :style="
            'margin-top: ' +
            getPopupHeight(index) +
            'px; opacity: ' +
            (1 - index * 0.1) +
            ';'
        "
    >
        <div class="content">
            <i
                v-if="notification.type == 'success'"
                class="fas fa-check-circle"
            ></i>
            <i
                v-if="notification.type == 'warning'"
                class="fas fa-exclamation-circle"
            ></i>
            <i
                v-if="notification.type == 'info'"
                class="fas fa-info-circle"
            ></i>
            <p>
                {{ notification.message }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { usePopupStore } from '@/js/stores/popupStore';

const popupStore = usePopupStore();

const getPopupHeight = index => {
    const popups = document.querySelectorAll('.popup');
    let height = 0;
    for (let i = 0; i < index; i++) {
        height += popups[i].offsetHeight + 10;
    }
    return height;
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.popup {
    position: fixed;
    top: 25px;
    right: 25px;
    width: fit-content;
    max-width: 350px;
    z-index: 9999;
    background-color: $whiteColor;
    border-radius: $borderRadius;
    box-shadow: $allShadows;

    &.success {
        border-left: 10px solid $successColor;
        .content {
            color: $successColor;
        }
    }
    &.warning {
        border-left: 10px solid $warningColor;
        .content {
            color: $warningColor;
        }
    }
    &.info {
        border-left: 10px solid $blackColor;
    }
    i {
        font-size: $defaultFontSize;
        margin: 0.25rem;
        cursor: pointer;
    }
    .content {
        padding: 0.25rem 1rem 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 2rem;
            margin-right: 1rem;
        }
        p {
            margin: 0;
        }
    }
}
</style>
