<template>
    <div style="display: none"></div>
</template>
<script setup>
import { useHead } from '@unhead/vue';
import { ref } from 'vue';

import metaDataJson from '@/js/json/metadata.json';

const props = defineProps({
    page: {
        type: String,
        required: false,
    },
    path: {
        type: String,
        required: false,
    },
    title: {
        type: String,
        required: false,
    },
    description: {
        type: String,
        required: false,
    },
    keywords: {
        type: String,
        required: false,
    },
    ogTitle: {
        type: String,
        required: false,
    },
    ogDescription: {
        type: String,
        required: false,
    },
    ogImage: {
        type: String,
        required: false,
    },
    ogUrl: {
        type: String,
        required: false,
    },
    ogType: {
        type: String,
        required: false,
    },
    isExternalApi: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const metaData = ref(null);

if (props.page) {
    metaData.value = metaDataJson.filter(item => item.page === props.page)[0];
}

function stripTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
}

const url = ref(
    props.isExternalApi
        ? 'https://api.kameobikes.com'
        : 'https://kameobikes.com',
);

const cutAt = (str, n) => {
    return str.length > n ? str.substr(0, n - 3) + '...' : str;
};

useHead({
    title:
        props.title && !!props.title
            ? props.title
            : metaData.value?.title
            ? metaData.value?.title
            : '',
    meta: [
        {
            name: 'description',
            content:
                props.description && !!props.description
                    ? cutAt(props.description, 250)
                    : metaData.value && metaData.value.description
                    ? cutAt(metaData.value.description, 250)
                    : '',
        },
        {
            name: 'keywords',
            content:
                props.keywords && !!props.keywords
                    ? props.keywords
                    : metaData.value
                    ? metaData.value.keywords
                    : '',
        },
        {
            property: 'og:title',
            content:
                props.title && !!props.title
                    ? props.title
                    : metaData.value && metaData.value['og:title']
                    ? metaData.value['og:title']
                    : '',
        },
        {
            property: 'og:description',
            content:
                props.description && !!props.description
                    ? cutAt(stripTags(props.description), 250)
                    : metaData.value &&
                      metaData.value['og:description'] &&
                      !!metaData.value['og:description']
                    ? cutAt(stripTags(metaData.value['og:description']), 250)
                    : '',
        },
        {
            property: 'og:image',
            content:
                props.ogImage && !!props.ogImage
                    ? url.value + props.ogImage
                    : metaData.value
                    ? url.value + metaData.value['og:image']
                    : '',
        },
        {
            property: 'og:url',
            content:
                props.path && !!props.path
                    ? 'https://kameobikes.com' + props.path
                    : metaData.value
                    ? metaData.value['og:url']
                    : '',
        },
        {
            property: 'og:type',
            content:
                props.ogType && !!props.ogType
                    ? props.ogType
                    : metaData.value
                    ? metaData.value['og:type']
                    : '',
        },
    ],
});
</script>
