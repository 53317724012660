<template>
    <GoogleAddressAutocomplete
        :apiKey="googleApiKey"
        @input="getAddressDetails"
        @callback="getAddressDetails"
        :id="uniqueId"
        placeholder="Chercher une adresse..."
    />
</template>
<script setup>
import { ref } from 'vue';
import GoogleAddressAutocomplete from 'vue3-google-address-autocomplete';

const googleApiKey = ref(import.meta.env.VITE_GOOGLE_API_KEY);
const uniqueId = ref(Math.random().toString(36).substring(7));

const emit = defineEmits(['updateAddress']);

function getAddressDetails(place) {
    // Initialize the object to store address components
    const address = {
        condensed: place.formatted_address ?? '',
        city: '',
        country: '',
        zip: '',
        street: '',
        number: '',
    };

    // Extract address components from the place object
    if (place.address_components) {
        place.address_components.forEach(component => {
            component.types.forEach(type => {
                if (type === 'locality') {
                    address.city = component.long_name;
                }
                if (type === 'country') {
                    address.country = component.long_name;
                }
                if (type === 'postal_code') {
                    address.zip = component.long_name;
                }
                if (type === 'route') {
                    address.street = component.long_name;
                }
                if (type === 'street_number') {
                    address.number = component.long_name;
                }
            });
        });
    }

    emit('updateAddress', address);
}
</script>
