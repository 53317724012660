export function useMixinMethods() {
    const $storageUrl = path => {
        const isProduction = import.meta.env.VITE_APP_ENV === 'production';
        // const baseUrl = isProduction
        //     ? import.meta.env.VITE_S3_URL
        //     : import.meta.env.VITE_API_URL;
        const baseUrl = import.meta.env.VITE_API_URL + '/storage';

        return `${baseUrl}${path}`;
    };

    return {
        $storageUrl,
    };
}
