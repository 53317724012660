<template>
    <Suspense>
        <div v-if="!isLoading">
            <RouterView />
            <PopUp />
            <LanguageSelector />
            <CookiesBanner />
        </div>
    </Suspense>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useAuthStore } from '@/js/stores/authStore';

import CookiesBanner from '@/js/components/CookiesBanner.vue';
import PopUp from '@/js/components/dashboard/InfoPopUp.vue';
import LanguageSelector from '@/js/components/LanguageSelector.vue';

const authStore = useAuthStore();
const isLoading = ref(true);

onMounted(async () => {
    if (authStore.isInitialized) {
        isLoading.value = false;
        return;
    } else {
        await authStore.init();
        isLoading.value = false;
    }
});
</script>
<style lang="scss" scoped></style>
