import { defineStore } from 'pinia';

import { useApiStore } from './apiStore';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isAuth: false,
        token: window.localStorage.getItem('auth_token'),
        error: null,
        user: {},
        apiStore: useApiStore(),
        isInitialized: false,
    }),
    actions: {
        async init() {
            await this.check();
            if (this.isAuth) {
                await this.setAuthUser();
            }
            this.isInitialized = true;
        },
        async check() {
            await this.apiStore
                .call('GET', '/api/auth/check')
                .then(response => {
                    this.isAuth = response.authenticated;
                })
                .catch(() => {
                    return false;
                });
        },
        async setAuthUser() {
            await this.apiStore
                .call('GET', '/api/user/authenticated')
                .then(response => {
                    this.user = response;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        login(form) {
            this.apiStore
                .call('POST', '/login', form)
                .then(response => {
                    this.token = response.token;
                    window.localStorage.setItem('auth_token', this.token);
                    this.isAuth = true;
                    this.user.language
                        ? (window.location.href =
                              '/' +
                              this.user.language.toLowerCase() +
                              '/dashboard')
                        : (window.location.href = '/fr/dashboard');
                })
                .catch(error => {
                    if (error.response?.data?.message) {
                        this.error = error.response.data.message;
                    } else {
                        this.error = 'Une erreur est survenue';
                    }
                });
        },
        logout() {
            this.apiStore.call('POST', '/logout').then(() => {
                window.location.href = '/connexion';
                setTimeout(() => {
                    this.isAuth = false;
                    this.token = null;
                    window.localStorage.removeItem('auth_token');
                }, 1000);
            });
        },
    },
});

export default useAuthStore;
