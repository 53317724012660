import { createI18n } from 'vue-i18n';

import fr from '@/js/json/lang/fr.json';
import en from '@/js/json/lang/en.json';
import nl from '@/js/json/lang/nl.json';

const i18n = createI18n({
    legacy: false,
    locale: window.location.pathname.split('/')[1] || 'fr',
    fallbackLocale: 'en',
    messages: {
        fr,
        en,
        nl,
    },
    availableLocales: ['fr', 'en', 'nl'],
});

export default i18n;
