<template>
    <div class="ctn">
        <div class="header">
            <div class="leftHeader">
                <slot name="left_header"></slot>
                <h1 class="title" v-text="props.title" />
            </div>

            <div class="rightHeader">
                <slot name="right_header"></slot>
            </div>
        </div>

        <hr class="line" />

        <div class="body">
            <slot name="content"></slot>
        </div>

        <hr class="line" v-if="$slots.info" />

        <div class="info" v-if="$slots.info">
            <slot name="info"></slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
    },
});
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.ctn {
    padding: 1.25rem 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .leftHeader {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            h1 {
                font-size: $tertiaryFontSize;
                font-weight: 700;
                margin: 0;
            }
            :nth-child(2) {
                margin: 0 0 0 1rem;
            }
        }

        .rightHeader {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    .line {
        width: 100%;
        margin: 1rem 0 0 0;
    }

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 1rem;
    }

    .info {
        width: 100%;
    }
}

@media screen and (max-width: $mediumBreakpoint) {
    .header {
        flex-direction: column !important;
        align-items: flex-start;
        div {
            margin: 1rem 0 0 0;
        }
    }
}
</style>
