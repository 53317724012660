<template>
    <i
        v-show="checkCookieConsent()"
        @click="updateModalIsOpen = !updateModalIsOpen"
        id="logoCookie"
        class="fas fa-cookie ml-4"
        title="Cookies preferences"
    ></i>
    <div class="bannerCookie" id="bannerCookie" ref="bannerCookie">
        <p>
            Nous respectons votre vie privée. Nous utilisons des cookies pour
            améliorer votre expérience de navigation, diffuser des publicités ou
            du contenu personnalisés et analyser notre trafic.<br />En cliquant
            sur "Accepter tout", vous consentez à notre utilisation des cookies.
        </p>

        <!-- Boutons -->
        <div class="buttons mb-2">
            <primary-button
                @click="setCookieConsent(true), reloadPage()"
                color="green"
                class="m-2"
            >
                Accepter tout
            </primary-button>
            <primary-button
                @click="
                    setCookieConsent(false),
                        disableAnalyticsCookies(),
                        reloadPage()
                "
                color="green"
                class="m-2"
            >
                Refuser tout
            </primary-button>
            <primary-button
                @click="updateModalIsOpen = true"
                color="green"
                class="m-2"
                >Personnaliser</primary-button
            >
        </div>
    </div>
    <modal
        :open="updateModalIsOpen"
        @close="updateModalIsOpen = !updateModalIsOpen"
    >
        <template #title> Mettre à jour les préférences de cookies </template>
        <template #content>
            <div class="modal-body">
                <p><strong>Politique de confidentialité :</strong></p>
                <p>
                    Nous utilisons des cookies pour vous aider à naviguer
                    efficacement et à exécuter certaines fonctions. Vous
                    trouverez des informations détaillées sur tous les cookies
                    sous chaque catégorie de consentement ci-dessous.
                </p>
                <p>
                    Les cookies classés comme "nécessaires" sont stockés sur
                    votre navigateur car ils sont essentiels pour activer les
                    fonctionnalités de base du site. Nous utilisons également
                    des cookies tiers qui nous aident à analyser la façon dont
                    vous utilisez ce site Web, à stocker vos préférences et à
                    fournir le contenu et les publicités qui vous intéressent.
                </p>
                <p>
                    Ces cookies ne seront stockés dans votre navigateur qu'avec
                    votre consentement préalable. Vous pouvez choisir d'activer
                    ou de désactiver certains où tous ces cookies, mais la
                    désactivation de certains d'entre eux peut affecter votre
                    expérience de navigation.
                </p>

                <hr />
                <div class="cookieObligatoire">
                    <p><strong>Cookies obligatoires :</strong></p>
                    <p>
                        Ces cookies sont nécessaires pour vous permettre de
                        naviguer sur notre site internet et d'utiliser ses
                        fonctionnalités, comme l'accès à des zones sécurisées du
                        site. Sans ces cookies, les services que vous demandez
                        ne peuvent pas être fournis.
                    </p>

                    <table>
                        <tr>
                            <th>Cookie</th>
                            <th>Durée</th>
                            <th>Description</th>
                        </tr>

                        <tr>
                            <td>kameo_bikes_session</td>
                            <td>2 heures</td>
                            <td>
                                Ce cookie permet de garder en mémoire votre
                                connexion à notre plateforme MyKameo, afin
                                d'éviter de vous demander votre login et mot de
                                passe de manière périodique.
                            </td>
                        </tr>

                        <tr>
                            <td>XSFR-TOKEN</td>
                            <td>2 heures</td>
                            <td>
                                Ce cookie permet d'éviter à notre site internet
                                d'être la cible de faille XSFR.
                            </td>
                        </tr>

                        <tr>
                            <td>cookie_consent</td>
                            <td>
                                Accepté 1 an
                                <br />
                                Refusé : 7 jours
                            </td>
                            <td>
                                Ce cookie permet de garder en mémoire vos
                                précédents choix en termes de politique de
                                confidentialité (GDPR).
                            </td>
                        </tr>

                        <tr>
                            <td>_cfduid (tiers)</td>
                            <td>30 jours</td>
                            <td>
                                Ce cookie est utilisé pour identifier de manière
                                unique un visiteur de site web et pour garantir
                                que le service de chat en direct fonctionne
                                correctement. Il est utilisé par le réseau de
                                diffusion de contenu Cloudflare, qui est utilisé
                                par Tidio pour améliorer la sécurité et la
                                performance de son service de chat en direct.
                            </td>
                        </tr>
                    </table>

                    <hr />
                </div>

                <div class="cookieAnalytic">
                    <p><strong>Cookies pour Analyse :</strong></p>
                    <div
                        v-if="
                            // Si le cookie existe on affiche le switch en mode 'on'
                            getCookieConsent()
                        "
                        class="form-check form-switch col-1 mx-auto"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchAnalytic"
                            checked
                        />
                    </div>
                    <div v-else class="form-check form-switch col-1 mx-auto">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchAnalytic"
                        />
                    </div>

                    <p>
                        Ces cookies nous permettent de mesurer et d'analyser
                        l'utilisation de notre site internet afin d'améliorer
                        son contenu et ses fonctionnalités.
                    </p>

                    <table>
                        <tr>
                            <th>Cookie</th>
                            <th>Durée</th>
                            <th>Description</th>
                        </tr>

                        <tr>
                            <td>_ga_T2EKX7RSBR</td>
                            <td>1 an</td>
                            <td>
                                Ce cookie est installé par Google analytics afin
                                d'analyser votre comportement sur l'entièreté de
                                notre site internet. Cela nous permet notamment
                                d'identifier le parcours type sur notre site
                                internet et ainsi proposer des alternatives
                                pertinentes.
                            </td>
                        </tr>

                        <tr>
                            <td>_ga</td>
                            <td>1 an</td>
                            <td>
                                Ce cookie est installé par Google analytics afin
                                d'analyser votre comportement sur l'entièreté de
                                notre site internet. Cela nous permet notamment
                                d'identifier le parcours type sur notre site
                                internet et ainsi proposer des alternatives
                                pertinentes.
                            </td>
                        </tr>
                    </table>
                </div>
                <br />
                <primary-button
                    @click="
                        () => {
                            (updateModalIsOpen = false),
                                setCookieWithUserChoice(),
                                reloadPage();
                        }
                    "
                    color="green"
                >
                    Sauvegarder les préférences
                </primary-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
import { onMounted, ref } from 'vue';

// Importer les composants

// Variables
const updateModalIsOpen = ref(false);

onMounted(() => {
    // Au démarrage de la page, si le cookie_consent n'existe pas ou si le cookie_consent vaut "refused", on désactive les cookies analytics
    if (
        document.cookie.indexOf('cookie_consent') == -1 ||
        document.cookie.indexOf('refused') != -1
    ) {
        disableAnalyticsCookies();
    }

    // Si l'acceptation de la politique de cookies n'a pas été enregistrée dans les cookies
    if (!checkCookieConsent()) {
        // Afficher la bannière de politique de cookies avec la possibilité d'accepter ou non
        document.getElementById('bannerCookie').style.display = 'block';
    } else {
        document.getElementById('bannerCookie').style.display = 'none';
    }
});

// ---------- COOKIE CONSENT ---------- //

// Fonction pour vérifier l'acceptation de la politique de cookies
function checkCookieConsent() {
    // Récupérer tous les cookies
    const cookies = document.cookie.split(';');

    // Boucle à travers tous les cookies pour trouver celui qui contient l'acceptation de la politique de cookies
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Si le cookie correspond à l'acceptation de la politique de cookies
        if (
            cookie.indexOf('cookie_consent=accepted') == 0 ||
            cookie.indexOf('cookie_consent=refused') == 0
        ) {
            // Retourne true si l'acceptation est enregistrée dans les cookies
            return true;
        }
    }

    // Retourne false si l'acceptation n'est pas enregistrée dans les cookies
    return false;
}

// Fonction pour enregistrer l'acceptation de la politique de cookies
function setCookieConsent(accept) {
    // Si l'utilisateur accepte la politique de cookies
    if (accept) {
        const expires = new Date();
        expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);

        // Enregistrer l'acceptation de la politique de cookies
        document.cookie =
            'cookie_consent=accepted; expires=' +
            expires.toUTCString() +
            '; path=/; SameSite=None; Secure';

        // Si l'utilisateur refuse la politique de cookies
    } else {
        const expires = new Date();
        expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Enregistrer le refus de la politique de cookies
        document.cookie =
            'cookie_consent=refused; expires=' +
            expires.toUTCString() +
            '; path=/; SameSite=None; Secure';
    }
}

// Fonction pour enregistrer les préférences de l'utilisateur
function setCookieWithUserChoice() {
    // Récupérer les préférences de l'utilisateur
    const acceptAnalytic =
        document.getElementById('flexSwitchAnalytic').checked;

    // Si l'utilisateur accepte les cookies analytics
    if (acceptAnalytic) {
        // Enregistrer l'acceptation de la politique de cookies
        setCookieConsent(true);
    } else {
        // Enregistrer le refus de la politique de cookies
        setCookieConsent(false);

        // Désactiver les cookies analytics si ils existent
        if (checkCookieAnalytics()) {
            disableAnalyticsCookies();
        }
    }
}

function getCookieConsent() {
    return document.cookie.indexOf('accepted') != -1;
}

// ---------- GOOGLE ANALYTICS ---------- //

// Fonction pour vérifier si les cookies analytics sont enregistrés
function checkCookieAnalytics() {
    // Récupérer tous les cookies
    const cookies = document.cookie.split(';');

    // Boucle à travers tous les cookies pour trouver ceux qui contiennent les cookies analytics
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Si le cookie correspond à un cookie analytics
        if (
            cookie.indexOf('_ga_T2EKX7RSBR=') == 0 ||
            cookie.indexOf('_ga=') == 0
        ) {
            // Retourne true si les cookies analytics sont enregistrés
            return true;
        }
    }

    // Retourne false si les cookies analytics ne sont pas enregistrés
    return false;
}

// Fonction pour supprimer les cookies analytics
function disableAnalyticsCookies() {
    document.cookie =
        '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=None; Secure';
    document.cookie =
        '_ga_T2EKX7RSBR=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=None; Secure';
}

function reloadPage() {
    window.location.reload();
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

#logoCookie {
    background-color: transparent;
    border-radius: 50%;
    color: $blackColor;
    width: 40px;
    height: 40px;
    font-size: 40px;
    position: fixed;
    bottom: 10px;
    left: 60px;
    cursor: pointer;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $defaultShadow;
}
.bannerCookie {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9998;
    transition: all 1s ease-in-out;
}
.bannerCookie p {
    margin-top: 20px;
}

.buttons button:hover {
    cursor: pointer;
    background-color: #2a7764;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-header {
    color: black;
    margin-top: 10px;
}

.modal-body {
    max-height: 600px;
    overflow-y: auto;
}

.modal-content {
    background-color: #fefefe;
    color: black;
    border: 1px solid #888;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table,
th,
td {
    border: 1px solid black;
}

th,
td {
    padding: 5px;
    text-align: left;
}

.cookieObligatoire table,
.cookieAnalytic table {
    margin-top: 20px;
}

.cookieObligatoire table th,
.cookieAnalytic table th {
    background-color: #3cb295;
    color: white;
}

@media screen and (max-width: $mediumBreakpoint) {
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .btn-container {
            margin: 0.5rem !important;
        }
    }
}
</style>
