<template>
    <div class="navigation-bar" :class="{ 'is-open': isOpen }">
        <button class="menu-btn" @click="$emit('toggle')">
            <i v-show="isOpen" class="fas fa-bars"></i>
            <i v-show="!isOpen" class="fa-solid fa-bars-staggered"></i>
        </button>
        <SearchBar v-if="authStore.$state.user.isAdmin" :isOpen="isOpen" />
        <UserDropdown :isOpen="isOpen" :user="authStore.$state.user" />
    </div>
</template>

<script setup>
import { useAuthStore } from '@/js/stores/authStore';

import SearchBar from './SearchBar.vue';
import UserDropdown from './UserDropdown.vue';

defineProps({
    isOpen: Boolean,
});

const authStore = useAuthStore();

defineEmits(['toggle']);
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.navigation-bar {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: $greenColor;
    transition: margin-left 0.3s ease-in-out;
    z-index: 2;
    .menu-btn {
        color: $whiteColor;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 1rem;
    }
}
.navigation-bar.is-open {
    margin-left: 300px;
}
</style>
