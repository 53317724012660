<!--
    - Events:
        - search

    Usage:
        <search-bar @search="fetchData" />
-->
<script setup>
import TextInput from '@/js/components/TextInput.vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['search']);

let interval = null;

const searchQuery = ref('');

const props = defineProps({
    search: String,
});
watch(
    () => props.search,
    () => {
        props.search
            ? (searchQuery.value = props.search)
            : (searchQuery.value = null);
    },
    { immediate: true },
);

const handleSearch = () => {
    clearInterval(interval);
    interval = setTimeout(() => {
        emit('search', searchQuery.value);
    }, 500);
};
</script>
<template>
    <TextInput
        type="text"
        v-model="searchQuery"
        @keyup.enter="handleSearch"
        @input="handleSearch"
        :placeholder="$translate('generic.search-placeholder')"
    />
</template>
