<template>
    <div class="layout">
        <slot />
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 20px;
    padding: 1.25rem;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: $mediumBreakpoint) {
    .layout {
        padding: 1.25rem 0.5rem;
    }
}
</style>
