<template>
    <transition name="fade">
        <div class="vue-modal" v-show="open" @click.self="cancel">
            <transition name="drop-in">
                <div class="vue-modal-inner">
                    <div class="body">
                        {{ message }}
                    </div>
                    <div class="button-container">
                        <primary-button
                            success
                            @click="emit('confirm')"
                            class="mr-2"
                        >
                            {{ confirmLabel }}
                        </primary-button>
                        <primary-button danger @click="cancel">
                            {{ cancelLabel }}
                        </primary-button>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>
<script setup>
defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        default: 'Êtes-vous sûr de vouloir continuer ?',
    },
    confirmLabel: {
        type: String,
        default: 'Oui',
    },
    cancelLabel: {
        type: String,
        default: 'Non',
    },
});

const emit = defineEmits(['cancel', 'confirm']);

const cancel = () => {
    emit('cancel');
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.vue-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .vue-modal-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $whiteColor;
        border-radius: $borderRadius;
        padding: 1rem;
        width: 50%;
        max-width: 50rem;
        max-height: 75%;
        box-shadow: $allShadows;
        overflow: auto;
        word-break: break-word;

        .body {
            padding: 1rem;
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            padding: 1rem;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
    transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
    opacity: 0;
}

@media screen and (max-width: $mediumBreakpoint) {
    .vue-modal-inner {
        width: 90% !important;
    }
}

.myStyle {
    height: 800px;
}
</style>
