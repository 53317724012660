import { defineStore } from 'pinia';

export const useDatatableStore = defineStore('datatable', {
    state: () => ({
        tables: {},
        lastTableId: null,
    }),
    getters: {
        getSelectedIsActive: state => tableId => {
            return state.tables[tableId]?.selectedIsActive ?? false;
        },
        getSelected: state => tableId => {
            return state.tables[tableId]?.selected ?? [];
        },
        getSelectedCount: state => tableId => {
            return (state.tables[tableId]?.selected ?? []).length;
        },
        allIsSelected: state => (tableId, data) => {
            const selected = state.tables[tableId]?.selected ?? [];
            return (
                data.length > 0 &&
                data.every(itemId => selected.includes(itemId))
            );
        },
        getSortColumn: state => tableId => {
            return state.tables[tableId]?.sortColumn ?? null;
        },
        getSortDirection: state => tableId => {
            return state.tables[tableId]?.sortDirection ?? null;
        },
        getFilters: state => tableId => {
            if (tableId === state.lastTableId) {
                return state.tables[tableId]?.filters ?? {};
            } else {
                return {};
            }
        },
    },
    actions: {
        toggleSelectedIsActive({ tableId }) {
            if (!this.tables[tableId]) {
                this.resetTable({ tableId });
            }
            this.tables[tableId].selectedIsActive =
                !this.tables[tableId].selectedIsActive;
        },
        addSelected({ tableId, value }) {
            if (!this.tables[tableId]) {
                this.resetTable({ tableId });
            }
            this.tables[tableId].selected.push(value);
        },
        removeSelected({ tableId, value }) {
            if (this.tables[tableId]) {
                this.tables[tableId].selected = this.tables[
                    tableId
                ].selected.filter(item => item !== value);
            }
        },
        selectAll({ tableId, data }) {
            if (!this.tables[tableId]) {
                this.resetTable({ tableId });
            }
            this.tables[tableId].selected = [
                ...new Set([...this.tables[tableId].selected, ...data]),
            ];
        },
        deselectAll({ tableId, data }) {
            if (this.tables[tableId]) {
                this.tables[tableId].selected = this.tables[
                    tableId
                ].selected.filter(item => !data.includes(item));
            }
        },
        toggleSelectAll({ tableId, data }) {
            if (this.allIsSelected(tableId, data)) {
                this.deselectAll({ tableId, data });
            } else {
                this.selectAll({ tableId, data });
            }
        },
        toggleSortColumn({ tableId, column }) {
            if (!this.tables[tableId]) {
                this.resetTable({ tableId });
            }
            if (this.tables[tableId].sortColumn === column) {
                this.tables[tableId].sortDirection =
                    this.tables[tableId].sortDirection === 'asc'
                        ? 'desc'
                        : 'asc';
            } else {
                this.tables[tableId].sortColumn = column;
                this.tables[tableId].sortDirection = 'asc';
            }
        },
        resetTable({ tableId }) {
            this.tables[tableId] = {
                selectedIsActive: false,
                selected: [],
                sortColumn: null,
                sortDirection: null,
                filters: {},
            };
        },
        setFilters({ tableId, filters }) {
            if (!this.tables[tableId]) {
                this.resetTable({ tableId });
            }
            this.tables[tableId].filters = filters;
        },
        setLastTableId(tableId) {
            this.lastTableId = tableId;
        },
        clearSelected(tableId) {
            if (this.tables[tableId]) {
                this.tables[tableId].selected = [];
                this.tables[tableId].selectedIsActive = false;
            }
        },
    },
});
