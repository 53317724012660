<template>
    <div class="user-dropdown" :class="{ 'is-open': isOpen }">
        <img
            :src="
                '/storage/assets/images/users/profil-pictures/' +
                user.id +
                '.jpg'
            "
            alt="Photo de profil"
            class="profile-photo"
            @error="
                e =>
                    (e.target.src =
                        '/storage/assets/images/users/profil-pictures/default.png')
            "
            @mouseout="toggleDropdown('exit')"
            @mouseover="toggleDropdown('enter')"
        />
        <i
            class="fas fa-chevron-down"
            @mouseout="toggleDropdown('exit')"
            @mouseover="toggleDropdown('enter')"
            @click="toggleDropdown()"
        ></i>
        <ul
            class="dropdown-menu"
            @mouseout="toggleDropdown('exit')"
            @mouseover="toggleDropdown('enter')"
            @click="toggleDropdown()"
        >
            <li
                @click="
                    $router.push({
                        name: 'user-settings',
                    })
                "
            >
                <span>{{
                    $translate('components.dashboard.menu.user.settings')
                }}</span>
            </li>
            <li
                v-if="user.isAdmin || user.isTemporaryUser"
                @click="openAccountSwitcherModal"
            >
                <span>Account switcher</span>
            </li>
            <li
                v-if="user.isAdmin"
                @click="
                    $router.push({
                        name: 'home-monitoring',
                    })
                "
            >
                <span>Monitoring</span>
            </li>
            <li
                v-if="user.isAdmin"
                @click="
                    $router.push({
                        name: 'mep',
                    })
                "
            >
                <span>Communication MEP</span>
            </li>
            <li
                @click="
                    user.isTemporaryUser
                        ? authStore.logout(true)
                        : authStore.logout()
                "
            >
                {{ $translate('components.dashboard.menu.user.logout') }}
            </li>
        </ul>
        <span
            class="user-name"
            @mouseout="toggleDropdown('exit')"
            @mouseover="toggleDropdown('enter')"
            >{{ user.firstname }}</span
        >
    </div>
    <modal
        v-if="openAccountSwitcherModalIsOpen"
        :open="openAccountSwitcherModalIsOpen"
        @close="openAccountSwitcherModalIsOpen = false"
    >
        <template #title>Account switcher</template>
        <template #content>
            <drop-down
                filter
                showClear
                :options="companies"
                optionLabel="name"
                optionValue="id"
                placeholder="Rechercher..."
                v-model="company"
                @change="company => updateAccountSwitcherInfo(company.value)"
                class="w-full mb-3"
            />

            <Listbox
                v-model="accountSwitcherInfo.permission"
                :options="permissions"
                multiple
                optionLabel=""
                class="w-full"
                :disabled="!accountSwitcherInfo.company_id"
            />

            <primary-button
                @click="switchAccount"
                class="w-fit-content"
                :disabled="!accountSwitcherInfo.company_id"
            >
                Switcher de compte
            </primary-button>
        </template>
    </modal>
</template>

<script setup>
import { ref } from 'vue';

import { useApiStore } from '@/js/stores/apiStore';
import { useAuthStore } from '@/js/stores/authStore';
import { usePopupStore } from '@/js/stores/popupStore';

import Listbox from 'primevue/listbox';
import { useRouter } from 'vue-router';

const apiStore = useApiStore();
const popupStore = usePopupStore();
const authStore = useAuthStore();

const $router = useRouter();

const openAccountSwitcherModalIsOpen = ref(false);
const companies = ref([]);
const accountSwitcherInfo = ref({
    company_id: null,
    permission: [],
});
const permissions = ref(['order', 'search', 'fleetManager', 'admin']);
defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
    user: {
        type: Object,
        default: null,
    },
});

const toggleDropdown = (exit = null) => {
    if (exit === 'exit') {
        return document
            .querySelector('.dropdown-menu')
            .classList.remove('show');
    } else if (exit === 'enter') {
        return document.querySelector('.dropdown-menu').classList.add('show');
    }
    return document.querySelector('.dropdown-menu').classList.toggle('show');
};

const openAccountSwitcherModal = () => {
    openAccountSwitcherModalIsOpen.value = true;
    apiStore
        .apiGet(`/api/companies/list/${false}`)
        .then(res => {
            companies.value = res.data;
        })
        .catch(() => {
            popupStore.show('warning');
        });
};

const updateAccountSwitcherInfo = company => {
    accountSwitcherInfo.value.company_id = company;
    accountSwitcherInfo.value.permission = ['fleetManager'];
    apiStore
        .apiGet('/api/conditions/get-conditions-by-company-id/' + company)
        .then(res => {
            if (res.access) {
                if (res.access.includes('partage')) {
                    accountSwitcherInfo.value.permission.push('search');
                } else if (res.access.includes('cafeteria')) {
                    accountSwitcherInfo.value.permission.push('order');
                }
            } else {
                popupStore.show('info', 'Aucune condition trouvée !');
            }
        })
        .catch(err => {
            console.log(err);
            popupStore.show('warning');
        });
};

const switchAccount = () => {
    apiStore
        .call('POST', '/api/users/tmp/create', accountSwitcherInfo.value)
        .then(res => {
            popupStore.show('success', 'Compte switché avec succès !');
            localStorage.setItem('auth_token', res.token);
            if ($router.currentRoute.value.name == 'dashboard') {
                $router.go();
            } else {
                window.location.href = '/fr/dashboard/';
            }
        })
        .catch(err => {
            console.log(err);
            popupStore.show('warning');
        });
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.user-dropdown {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: margin-right 0.3s ease-in-out;
    .profile-photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .user-name {
        padding: 0.75rem 0;
        margin-right: 1rem;
        font-size: $defaultFontSize;
        font-weight: bold;
    }

    i {
        font-size: 0.8rem;
        cursor: pointer;
        padding: 1rem;
    }

    .dropdown-menu {
        position: fixed;
        top: 50px;
        right: 0.5rem;
        background-color: $whiteColor;
        border-radius: $borderRadius;
        padding: 0.5rem 0;
        box-shadow: $allShadows;
        display: none;
        z-index: 1000;
        li {
            list-style: none;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: $greenColor;
                color: $whiteColor;
            }

            a {
                color: $blackColor;
            }
        }
    }

    .dropdown-menu.show {
        display: block;
    }
}
.user-dropdown.is-open {
    margin-right: 300px;
}

@media screen and (max-width: $mediumBreakpoint) {
    .user-name {
        display: none;
    }
}
</style>
