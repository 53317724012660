<!--
    Props:
        - data: Object
    Events:
        - updatePage
    Methods:
        - nextPage
        - prevPage

    Usage:
        <pagination :data="pagination" @updatePage="fetchData" />
-->
<script setup>
import { ref, watchEffect } from 'vue';

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['updatePage']);

const pagination = ref(props.data);

watchEffect(() => {
    pagination.value = props.data;
    if (pagination.value.current_page > pagination.value.last_page) {
        // eslint-disable-next-line camelcase
        pagination.value.current_page = 1;
        emit('updatePage', pagination.value.current_page);
    }
});

const nextPage = () => {
    if (pagination.value.current_page < pagination.value.last_page) {
        pagination.value.current_page++;
        emit('updatePage', pagination.value.current_page);
    }
};

const prevPage = () => {
    if (pagination.value.current_page > 1) {
        pagination.value.current_page--;
        emit('updatePage', pagination.value.current_page);
    }
};
</script>
<template>
    <div class="flex justify-center items-center mt-4">
        <button
            @click="prevPage"
            :disabled="pagination.current_page === 1"
            class="prev-page"
        >
            {{ $translate('generic.previous') }}
        </button>
        <span class="page-info">
            {{ pagination.current_page }} sur {{ pagination.last_page }}
        </span>
        <button
            @click="nextPage"
            :disabled="pagination.current_page === pagination.last_page"
            class="next-page"
        >
            {{ $translate('generic.next') }}
        </button>
    </div>
</template>
<style lang="scss" scoped>
button {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
    width: 125px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.page-info {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

@media screen and (max-width: 768px) {
    button {
        padding: 0.25rem 0.5rem;
        width: 100px;
        margin: 0 !important;
    }

    .page-info {
        text-align: center;
        padding: 0.25rem 0.5rem;
        margin: 0 !important;
    }
}
</style>
