export default {
    root: ({ props }) => ({
        class: [
            'rounded-md',
            // Colors
            { 'bg-surface-0': !props.disabled },
            'text-surface-700',
            'border',
            { 'border-surface-300': !props.invalid },
            // Disabled State
            {
                'bg-surface-200 select-none pointer-events-none cursor-default':
                    props.disabled,
            },
            // Invalid State
            { 'border-red-500': props.invalid },
        ],
    }),
    wrapper: {
        class: ['overflow-auto'],
    },
    list: {
        class: 'p-1 list-none m-0 outline-none',
    },
    item: ({ context }) => ({
        class: [
            'relative',
            // Font
            'leading-none',
            // Spacing
            'm-0 px-3 py-2',
            'first:mt-0 mt-[2px]',
            // Shape
            'border-0 rounded',
            // Colors
            {
                'text-surface-700':
                    (!context.focused || context.focused) && !context.selected,
                'bg-surface-200': context.focused && !context.selected,
                'text-primary-highlight-inverse': context.selected,
                'bg-primary-highlight': context.selected,
            },
            //States
            {
                'hover:bg-surface-100': !context.focused && !context.selected,
            },
            { 'hover:bg-primary-highlight-hover': context.selected },
            {
                'hover:text-surface-700 hover:bg-surface-100':
                    context.focused && !context.selected,
            },
            // Transition
            'transition-shadow duration-200',
            // Misc
            'cursor-pointer overflow-hidden whitespace-nowrap',
        ],
    }),
    itemgroup: {
        class: [
            'font-semibold',
            'm-0 py-2 px-3',
            'text-surface-400',
            'cursor-auto',
        ],
    },
    emptymessage: {
        class: [
            'leading-none',
            'py-2 px-3',
            'text-surface-800',
            'bg-transparent',
        ],
    },
    header: {
        class: [
            'pt-2 px-2 pb-0',
            'm-0',
            'border-b-0',
            'rounded-tl-md',
            'rounded-tr-md',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-300',
        ],
    },
    filtercontainer: {
        class: 'relative',
    },
    filterinput: {
        class: [
            'leading-[normal]',
            'py-2 pl-3 pr-7',
            '-mr-7',
            'w-full',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-200',
            'border',
            'rounded-lg',
            'appearance-none',
            'transition',
            'duration-200',
            'hover:border-surface-400',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 focus:z-10',
            'appearance-none',
        ],
    },
    filtericon: {
        class: ['absolute', 'top-1/2 right-3', '-mt-2'],
    },
};
