// Import dependencies
import axios from 'axios';

// Import auth store
import { useAuthStore } from '@/js/stores/authStore.js';

// Add the error handler to the application
export default function registerErrorHandler(app) {
    const authStore = useAuthStore();

    app.config.errorHandler = (err, vm, info) => {
        console.error(err);
        axios
            .post('/api/log/errors/front', {
                error: {
                    message: err.message ?? null,
                },
                userAgent: navigator.userAgent ?? null,
                info: info ?? null,
                user: {
                    id: authStore.user.id ?? null,
                    name:
                        authStore.user.firstname +
                            ' ' +
                            authStore.user.lastname ?? null,
                    email: authStore.user.email ?? null,
                },
                url: {
                    current: window.location.href ?? null,
                    previous: document.referrer ?? null,
                    params: vm.$route.params ?? null,
                    query: vm.$route.query ?? null,
                },
            })
            .then(res => {
                if (res.data.redirect) {
                    window.location.href = '/an-error-has-occurred';
                }
            });
    };
}
