<template>
    <div class="search">
        <input
            type="text"
            placeholder="Rechercher"
            @focus="searchIsFocused = true"
            @keypress.enter="search"
        />
        <i class="fas fa-search"></i>
        <div
            class="search-results"
            v-show="searchIsFocused"
            :class="{ menuIsOpen: isOpen }"
        >
            <i class="fas fa-times close" @click="closeSearchResults"></i>
            <drop-down
                class="w-full md:w-44 mb-2"
                v-model="filterType"
                optionLabel="label"
                optionValue="value"
                :options="[
                    { value: 'all', label: 'Tout' },
                    { value: 'bike', label: 'Vélo (stock)' },
                    { value: 'accessory', label: 'Accessoire (stock)' },
                    { value: 'bike_catalog', label: 'Vélo (catalogue)' },
                    {
                        value: 'accessory_catalog',
                        label: 'Accessoire (catalogue)',
                    },
                    { value: 'entretien', label: 'Entretien' },
                    { value: 'user', label: 'Utilisateur' },
                    { value: 'company', label: 'Entreprise' },
                    { value: 'offer', label: 'Offre' },
                ]"
            />
            <div class="results">
                <h3>Résultats de la recherche :</h3>
                <div class="result-container">
                    <!-- Entretien -->
                    <div
                        class="result"
                        v-for="entretien in searchResults?.entretiens"
                        :key="entretien.id"
                        v-show="
                            filterType == 'entretien' || filterType == 'all'
                        "
                        @click="
                            () => {
                                $router.push({
                                    name: 'entretiens-details',
                                    params: { id: entretien.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/bikes_pictures/' +
                                    entretien.bike?.bikes_catalog_id +
                                    '/A.jpg'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Entretien</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ entretien.id }} -
                                {{
                                    entretien.external_bike
                                        ? entretien.external_bike?.brand +
                                          ' ' +
                                          entretien.external_bike?.model
                                        : entretien.bike?.catalog?.brand +
                                          ' ' +
                                          entretien.bike?.catalog?.model
                                }}
                            </h4>
                            <p>
                                {{ entretien.bike?.company?.name }}
                                {{
                                    !!entretien.bike?.user?.firstname &&
                                    !!entretien.bike?.user?.lastname
                                        ? ' - ' +
                                          entretien.bike.user.firstname +
                                          ' ' +
                                          entretien.bike.user.lastname
                                        : ''
                                }}
                            </p>
                            <p>{{ entretien.date }}</p>
                        </div>
                    </div>
                    <!-- Vélo -->
                    <div
                        class="result"
                        v-for="bike in searchResults?.bikes"
                        :key="bike.id"
                        v-show="filterType == 'bike' || filterType == 'all'"
                        @click="
                            () => {
                                $router.push({
                                    name: 'bikes-stock-details',
                                    params: { id: bike.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/bikes_pictures/' +
                                    bike.bikes_catalog_id +
                                    '/A.jpg'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Stock vélo</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ bike.id }} -
                                {{ bike.catalog?.brand }}
                                {{ bike.catalog?.model }}
                            </h4>
                            <p>
                                {{ bike.company.name }}
                                {{
                                    !!bike?.user?.firstname &&
                                    !!bike?.user?.lastname
                                        ? ' - ' +
                                          bike.user.firstname +
                                          ' ' +
                                          bike.user.lastname
                                        : ''
                                }}
                            </p>
                            <p>{{ bike.contract_type }}</p>
                        </div>
                    </div>
                    <!-- Accessoire -->
                    <div
                        class="result"
                        v-for="accessory in searchResults?.accessories"
                        :key="accessory.id"
                        v-show="
                            filterType == 'accessory' || filterType == 'all'
                        "
                        @click="
                            () => {
                                $router.push({
                                    name: 'accessories-stock-details',
                                    params: { id: accessory.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/accessories_pictures/' +
                                    accessory.accessories_catalog_id +
                                    '/A.jpg'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Stock accessoire</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ accessory.id }} -
                                {{ accessory.catalog?.brand }}
                                {{ accessory.catalog?.model }}
                            </h4>
                            <p>
                                {{ accessory.company?.name }}
                                {{
                                    !!accessory.user?.firstname &&
                                    !!accessory.user?.lastname
                                        ? ' - ' +
                                          accessory.userFirstname +
                                          ' ' +
                                          accessory.userLastname
                                        : ''
                                }}
                            </p>
                            <p>{{ accessory.contract_type }}</p>
                        </div>
                    </div>
                    <!-- Vélo Catalogue -->
                    <div
                        class="result"
                        v-for="bike in searchResults?.bikes_catalog"
                        :key="bike.bikes_catalog_id"
                        v-show="
                            filterType == 'bike_catalog' || filterType == 'all'
                        "
                        @click="
                            () => {
                                $router.push({
                                    name: 'bikes-catalog-details',
                                    params: { id: bike.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/bikes_pictures/' +
                                    bike.id +
                                    '/A.jpg'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Catalogue vélo</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ bike.id }} -
                                {{ bike.catalog?.brand }}
                                {{ bike.catalog?.model }}
                            </h4>
                        </div>
                    </div>
                    <!-- Accessoire -->
                    <div
                        class="result"
                        v-for="accessory in searchResults?.accessories_catalog"
                        :key="accessory.accessories_catalog_id"
                        v-show="
                            filterType == 'accessory_catalog' ||
                            filterType == 'all'
                        "
                        @click="
                            () => {
                                $router.push({
                                    name: 'accessories-catalog-details',
                                    params: {
                                        id: accessory.id,
                                    },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/accessories_pictures/' +
                                    accessory.id +
                                    '/A.jpg'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Catalogue accessoire</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ accessory.id }} -
                                {{ accessory.catalog?.brand }}
                                {{ accessory.catalog?.model }}
                            </h4>
                        </div>
                    </div>
                    <!-- Utilisateur -->
                    <div
                        class="result"
                        v-for="user in searchResults?.users"
                        :key="user.id"
                        v-show="filterType == 'user' || filterType == 'all'"
                        @click="
                            () => {
                                $router.push({
                                    name: 'users-details',
                                    params: { id: user.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/users/profil-pictures/' +
                                    user.id +
                                    '.png'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Utilisateur</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ user.id }} -
                                {{ user.firstname }}
                                {{ user.lastname }}
                            </h4>
                            <p>
                                {{ user.company?.name }}
                            </p>
                        </div>
                    </div>
                    <!-- Société -->
                    <div
                        class="result"
                        v-for="company in searchResults?.companies"
                        :key="company.id"
                        v-show="filterType == 'company' || filterType == 'all'"
                        @click="
                            () => {
                                $router.push({
                                    name: 'companies-details',
                                    params: { id: company.id },
                                });
                                closeSearchResults();
                            }
                        "
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/company/' +
                                    company.id +
                                    '.png'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Société</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ company.id }} -
                                {{ company.name }}
                            </h4>
                        </div>
                    </div>
                    <!-- Offre -->
                    <div
                        class="result"
                        v-for="offer in searchResults?.offers"
                        :key="offer.id"
                        v-show="filterType == 'offer' || filterType == 'all'"
                    >
                        <div>
                            <img
                                :src="
                                    '/storage/assets/images/company/' +
                                    offer.company?.id +
                                    '.png'
                                "
                                @error="
                                    e =>
                                        (e.target.src =
                                            '/storage/assets/images/default.png')
                                "
                                alt="Logo"
                            />
                            <p class="type">Offre</p>
                        </div>
                        <div class="content">
                            <h4>
                                {{ offer.id }} -
                                {{ offer.title }}
                            </h4>
                            <p>
                                {{ offer.company?.name }}
                                {{
                                    !!offer.user?.firstname &&
                                    !!offer.user?.lastname
                                        ? ' - ' +
                                          offer.userFirstname +
                                          ' ' +
                                          offer.userLastname
                                        : ''
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <p
                    v-show="
                        searchResults.entretiens?.length == 0 &&
                        searchResults.bikes?.length == 0 &&
                        searchResults.accessories?.length == 0 &&
                        searchResults.users?.length == 0 &&
                        searchResults.companies?.length == 0 &&
                        searchResults.offers?.length == 0
                    "
                >
                    Aucun résultat... 🙁
                </p>
                <p v-show="searchResults.length == 0">
                    Tapez quelque chose pour commencer à chercher ! 🤔
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

import { useApiStore } from '@/js/stores/apiStore';

const apiStore = useApiStore();

const searchResults = ref([]);
const searchIsFocused = ref(false);
const filterType = ref('all');

let debounceTimeout;

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const search = e => {
    searchIsFocused.value = true;
    const searchValue = e.target.value;

    // Annulez le délai d'attente précédent
    clearTimeout(debounceTimeout);

    // Définissez un nouveau délai d'attente
    debounceTimeout = setTimeout(() => {
        if (searchValue) {
            apiStore.apiGet('/api/super-search/' + searchValue).then(res => {
                searchResults.value = res;
            });
        } else {
            searchResults.value = [];
        }
    }, 500);
};

const closeSearchResults = () => {
    searchResults.value = [];
    searchIsFocused.value = false;
    filterType.value = 'all';
    document.querySelector('.search input').value = '';
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.search {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
        border: none;
        border-radius: 50px;
        padding: 0.5rem 2.5rem 0.5rem 1rem;
        margin: 0;
        background-color: $whiteColor;
        box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.1),
            inset 0 0 10px 2px rgba(0, 0, 0, 0.06),
            inset 0 0 10px 1px rgba(255, 255, 255, 0.5);
    }

    i {
        margin-left: -2rem;
        color: $greenColor;
    }

    .search-results {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 60px;
        left: 0;
        width: 100%;
        min-height: 50vh;
        max-height: 50vh;
        background: $whiteColor;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
        padding: 1rem;
        overflow-y: auto;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        transition: $defaultTransition;
        &.menuIsOpen {
            left: 300px;
            width: calc(100% - 300px);
        }
        .close {
            position: absolute;
            top: 0.75rem;
            right: 1rem;
            font-size: 1.5rem;
            cursor: pointer;
            color: $redColor;
        }
        .filter {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0 1rem;
            .type {
                width: 150px;
            }
        }
        .results {
            width: 100%;
            padding-left: 1rem;
            h3 {
                font-size: $primaryFontSize;
                font-weight: bold;
                margin-bottom: 1rem;
                color: $greenColor;
            }
            .result-container {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                .result {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0.5rem;
                    cursor: pointer;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        color: $greenColor;
                        transition: $defaultTransition;
                    }
                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        img {
                            width: 100px;
                        }
                        p {
                            margin: 0.25rem 0 0 0;
                            font-size: 0.8rem;
                            color: $greyColor;
                        }
                    }
                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-left: 1rem;
                        h4 {
                            font-size: $defaultFontSize;
                            margin: 0.25rem 0;
                        }
                        p {
                            font-size: 0.8rem;
                            color: $greyColor;
                        }
                    }
                }
            }
        }
    }
}
</style>
