import { defineStore } from 'pinia';

export const usePopupStore = defineStore('popup', {
    state: () => ({
        notifications: [],
        confirm: null,
    }),
    actions: {
        show(type, message = null) {
            if (message == null) {
                switch (type) {
                    case 'success':
                        message = 'Mise à jour réussie !';
                        break;
                    case 'warning':
                        message = 'Une erreur est survenue...';
                        break;
                    case 'info':
                        message = 'Info !';
                        break;
                    default:
                        message = 'Info !';
                        break;
                }
            }
            this.notifications.unshift({
                type,
                message,
            });
            setTimeout(() => {
                this.hide();
            }, 5000);
        },
        hide() {
            this.notifications.pop();
        },
        confirm(validation) {
            this.confirm = validation;
        },
        cancel() {
            this.confirm = null;
        },
    },
});

export default usePopupStore;
