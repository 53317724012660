<template>
    <div class="btn-container">
        <button
            :class="{
                myButton: true,
                loading: props.loading,
                isNotActive: props.disabled,
                noStyle: props.noStyle,
            }"
            :style="props.customStyle"
            @click.prevent="handleClick(false)"
            @mousedown.middle="handleClick(true)"
        >
            <slot />
            <span v-if="props.loading" class="spinner">
                <i class="fas fa-spinner fa-spin"></i>
            </span>
        </button>
        <modal :open="modalIsOpen" @close="modalIsOpen = false">
            <template v-slot:title>Confirmation</template>
            <template v-slot:content>
                <p>
                    {{ props.validationMessage }}
                </p>
                <button class="btn confirm" @click="handleConfirm">
                    {{ props.validationButtonText }}
                </button>
                <button class="btn cancel" @click="handleCancel">
                    {{ props.cancelButtonText }}
                </button>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const modalIsOpen = ref(false);

const emit = defineEmits(['click']);

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    validation: {
        type: Boolean,
        default: false,
    },
    validationMessage: {
        type: String,
        default:
            'Êtes-vous sûr de vouloir faire cette action ? Celle-ci est irréversible.',
    },
    validationButtonText: {
        type: String,
        default: 'Confirmer',
    },
    cancelButtonText: {
        type: String,
        default: 'Annuler',
    },
    customStyle: {
        type: String,
        default: '',
    },
    noStyle: {
        type: Boolean,
        default: false,
    },
});

const handleConfirm = () => {
    modalIsOpen.value = false;
    emit('click', false);
};

const handleCancel = () => {
    modalIsOpen.value = false;
};

const handleClick = (newTab = false) => {
    if (props.validation) {
        modalIsOpen.value = true;
    } else {
        if (newTab) {
            emit('click', true);
        } else {
            emit('click', false);
        }
    }
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';

.btn-container {
    display: inline-block;
}

.myButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $defaultFontSize;
    text-transform: uppercase;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: $borderRadius;
    background-color: $greenColor;
    color: $whiteColor;
    cursor: pointer;
    transition: $defaultTransition;
    width: fit-content;
    // margin: 1rem auto;
    &:hover {
        opacity: 0.8;
    }
}

.myButton.loading {
    background-color: $greenColor;
    color: $whiteColor;
}

.myButton:hover {
    background-color: $greenColor;
    color: $whiteColor;
}

.myButton:active {
    transform: scale(0.95);
}

.isNotActive {
    opacity: 0.5;
    pointer-events: none;
}

.spinner {
    font-size: 1.2rem;
    vertical-align: middle;
}

.confirm {
    background-color: $greenColor;
    color: $whiteColor;
    margin-right: 0.5rem;
    &:hover {
        background-color: $greenColor;
        color: $whiteColor;
    }
}

.cancel {
    background-color: $redColor;
    color: $whiteColor;
    &:hover {
        background-color: $redColor;
        color: $whiteColor;
    }
}

.noStyle {
    background-color: transparent;
    color: $greenColor;
    padding: 0;
    margin: 0;
    text-transform: none;
    &:hover {
        color: $greenColor;
        background-color: transparent;
        text-decoration: underline;
        opacity: 1;
    }
}
</style>
