import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'leaflet/dist/leaflet.css';
import './bootstrap';

import i18n from '@/js/json/lang/config.js';
import pinia from '@/js/stores/config.js';
import { createGtm } from '@gtm-support/vue-gtm';
import { createHead } from '@unhead/vue';
import PrimeVue from 'primevue/config';

import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueTidio from 'vue-tidio';

import registerComponents from '@/js/components/index';
import registerErrorHandler from '@/js/functions/registerErrorHandler';
import registerLayouts from '@/js/layouts/index';
import router from '@/js/router/config';
import { useAuthStore } from '@/js/stores/authStore.js';
import App from '@/js/views/App.vue';
import '@/scss/app.scss';

import Lara from '@/js/components/primeVue';
import { useMixinMethods } from './composable/useMixinMethods';

import * as Sentry from '@sentry/vue';

const app = createApp(App);
const head = createHead();
app.use(i18n);
app.use(pinia);
app.use(head);
app.use(router);
app.use(PrimeVue, {
    pt: Lara,
    unstyled: true,
});

const authStore = useAuthStore();
const { $storageUrl } = useMixinMethods();

app.use(
    createGtm({
        id: import.meta.env.VITE_GTM_ID,
        enabled: import.meta.env.VITE_APP_ENV === 'production' ? true : false,
        debug: import.meta.env.VITE_APP_ENV === 'production' ? false : true,
        vueRouter: router,
    }),
);
app.use(
    VueGtag,
    {
        config: { id: import.meta.env.VITE_GA_ID },
        enabled: import.meta.env.VITE_APP_ENV === 'production' ? true : false,
    },
    router,
);
app.use(VueTidio, { appKey: 'egtbtnp8nm84ok8ihhzjh7vfll5e4m5j' });
app.mixin({
    methods: {
        $translate(key) {
            return i18n.global.t(key);
        },
        $hasAccess(...access) {
            return access.some(access =>
                authStore.$state.user?.company?.conditions[0]?.access?.includes(
                    access,
                ),
            );
        },
        $apiUrl() {
            return import.meta.env.VITE_API_URL;
        },
        $storageUrl,
    },
});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
});

registerComponents(app);
registerLayouts(app);
registerErrorHandler(app);
app.mount('#app');
