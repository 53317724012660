import axios from 'axios';
import { defineStore } from 'pinia';

export const useApiStore = defineStore('api', {
    state: () => ({
        errors: [],
    }),
    actions: {
        call(method, url, data = null, params = null) {
            this.resetErrors();
            return new Promise((resolve, reject) => {
                const axiosConfig = {
                    method,
                    url,
                    data,
                    params,
                };

                axios(axiosConfig)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            this.errors = error.response.data.errors;
                        } else if (error?.response?.status === 401) {
                            axios.post('/logout').then(() => {
                                window.localStorage.removeItem('auth_token');
                                window.location.href = '/login';
                            });
                        } else if (error?.response?.status === 404) {
                            const currentPage = window.location.pathname;
                            if (!currentPage.includes('dashboard')) {
                                //window.location.href = '/404';
                            }
                        } else {
                            this.errors = [
                                {
                                    message:
                                        'Une erreur est survenue, veuillez réessayer.',
                                },
                            ];
                        }
                        reject(error);
                    });
            });
        },
        apiGet(url, params = null) {
            return this.call(
                'get',
                import.meta.env.VITE_API_URL + url,
                null,
                params,
            );
        },
        apiPost(url, data) {
            return this.call('post', import.meta.env.VITE_API_URL + url, data);
        },
        apiPut(url, data) {
            return this.call('put', import.meta.env.VITE_API_URL + url, data);
        },
        apiDelete(url, data) {
            return this.call(
                'delete',
                import.meta.env.VITE_API_URL + url,
                data,
            );
        },
        resetErrors() {
            this.errors = [];
        },
    },
});

export default useApiStore;
