<template>
    <Menu @toggle="toggleMenu" :isOpen="menuIsOpen" />
    <div class="content-wrapper" :class="{ 'menu-open': menuIsOpen }">
        <slot />
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Menu from '@/js/components/dashboard/menu/Menu.vue';

const menuIsOpen = ref(window.innerWidth > 768 ? true : false);

const toggleMenu = () => {
    menuIsOpen.value = !menuIsOpen.value;
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.content-wrapper {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    transition: margin-left 0.3s ease-in-out;
    &.menu-open {
        width: calc(100% - 300px);
        margin-left: 300px;
    }
}

@media screen and (max-width: $mediumBreakpoint) {
    .content-wrapper {
        &.menu-open {
            margin-left: 0;
            opacity: 0;
            pointer-events: none;
        }
    }
}
</style>
