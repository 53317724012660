import Modal from '@/js/components/Modal.vue';
import BaseLayout from '@/js/components/dashboard/BaseLayout.vue';
import GoogleSearchInput from '@/js/components/dashboard/GoogleSearchInput.vue';
import SearchSelect from '@/js/components/dashboard/SearchSelect.vue';
import Datatable from '@/js/components/datatable/Datatable.vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import MetaData from './MetaData.vue';
import Validation from './Validation.vue';
import LinkButton from './buttons/LinkButton.vue';
import PrimaryButton from './buttons/PrimaryButton.vue';
import SimpleButton from './buttons/SimpleButton.vue';
import Pagination from './datatable/Pagination.vue';
import SearchBar from './datatable/SearchBar.vue';
export default function registerComponents(Vue) {
    Vue.component('meta-data', MetaData);
    Vue.component('simple-button', SimpleButton);
    Vue.component('base-layout', BaseLayout);
    Vue.component('search-select', SearchSelect);
    Vue.component('modal', Modal);
    Vue.component('google-input', GoogleSearchInput);
    Vue.component('data-table', Datatable);
    Vue.component('search-bar', SearchBar);
    Vue.component('pagination', Pagination);
    Vue.component('primary-button', PrimaryButton);
    Vue.component('link-button', LinkButton);
    Vue.component('validation', Validation);
    Vue.component('multi-select', MultiSelect);
    Vue.component('drop-down', Dropdown);
}
