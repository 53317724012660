<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel({
    type: [String, Number, null],
    required: true,
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        class="border-gray-300 rounded-md shadow-sm disabled:opacity-50 disabled:bg-gray-100"
        v-model="model"
        ref="input"
    />
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

input:focus {
    border-color: $lightGreenColor;
}
</style>
