<template>
    <select name="language" v-model="locale" @change="switchLanguage(locale)">
        <option v-for="lang in availableLocales" :key="lang" :value="lang">
            {{ lang }}
        </option>
    </select>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { locale, availableLocales } = useI18n();

const switchLanguage = newLocale => {
    const currentRoute = router.currentRoute.value;
    const name = currentRoute.name;
    const params = {
        ...currentRoute.params,
        lang: encodeURIComponent(newLocale),
    };
    const query = currentRoute.query;
    router.push({
        name,
        params,
        query,
        hash: '',
    });
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
select {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: $blackColor;
    color: $whiteColor;
    border: none;
    border-radius: $borderRadius;
    padding: 10px 10px;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: $defaultShadow;
    margin: 0;
    z-index: 9999;
    cursor: pointer;
}
</style>
