<template>
    <Drawner :isOpen="isOpen" @toggle="toggleMenu" />
    <NavigationBar :isOpen="isOpen" @toggle="toggleMenu" />
</template>

<script setup>
import Drawner from './partials/Drawner.vue';
import NavigationBar from './partials/NavigationBar.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emits = defineEmits(['toggle']);

const toggleMenu = () => {
    emits('toggle', !props.isOpen);
};
</script>
